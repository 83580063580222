.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.picture{
  width: 30%;
  height: 100%;
}
.box2{
  width: 70%;
}
.image-container {
  width: 100%; /* Set the image width to 100% of the parent container */
  height: 100%; /* Set the image height to 100% of the parent container */
  object-fit: cover; /* Optional: To maintain aspect ratio and cover the entire container */
  padding-top: .9vw;
  padding-right: 1vw;
  padding-bottom: 1vw;
}
.heading{
  font-family: 'Libre Baskerville', serif;
}
.paragraph{
  font-family: 'Kenia', cursive;
}.bigContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: green 2px solid;
  flex-wrap: wrap;
}
.logo {
  position: relative;
  top: -8px; /* Adjust the value to control how much the logo protrudes out */
  z-index: 1; /* Ensure the logo appears above the navbar background */
}
.logo img {
  position: relative;
  top: -10px; /* Adjust the value to control how much the logo protrudes out */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Add a shadow for the 3D effect */
}

/* Home Screen CasrouselWithCntent Main Image CSS*/
section {
  display: block;
}

#hero {
  width: 100%;
  height: calc(100vh - 109px);
  position: relative;
  overflow: hidden;
}
#hero:after {
  content: '';
  display: block;
  position: absolute;
  width: calc(100vw + 50px);
  height: 100%;
  background-size: cover;
  background-position: center;
  transform: translateX(0px);
  animation: heroAnim 10s linear infinite alternate;
  transition: all 1s ease-out;
}

.heroSlide1:after {
  background-image: url(../public/assets/images/jaikedar1.jpg);
}

/* Media Query for Mobile Phones */
@media (max-width: 768px) {
  #hero:after {
    width: calc(100vw + 20px); /* Adjust width for mobile screens */
  }

  .heroSlide1:after {
    background-size: contain; /* Adjust background size for mobile screens */
  }
}

/* *, ::after, ::before {
  box-sizing: border-box;
} */

#hero .sideShade {
  width: 0%;
  height: 0%;
  background-color: #014421D0;
  position: absolute;
  z-index: 2;
  right: 0px;
  clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
  animation: sideShadeAnim 0.0s cubic-bezier(.38,1.08,.98,1) 1s 1 forwards;
}
/* #hero .sideShade h4 {
  letter-spacing: 5px;
}
@keyframes sideShadeAnim{
	100%{
		clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
	}
} */
@media only screen and (max-width: 700px) {
    #hero .sideShade {
        width: 100%;
        text-align: center
    }
    #hero .sideShade h1{
        text-align: center
    }
    #quickLinks img {
        width: 40px;
        height: 40px;
    }
    #quickLinks p{
        font-size   : 14px;
    }
}

.fullscreen-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center;
  filter: brightness(50%);
  display: flex;
  justify-content: center;
  align-items: center; 
}

.image-text {
  position: absolute;
  top: 50%; /* Position the element 50% from the top */
  left: 50%; /* Position the element 50% from the left */
  transform: translate(-50%, -50%); /* Center the element */
  text-align: center;
  color: white;
  filter: brightness(200%);
}

.custom-bg-color {
  background-color: #87CEEB; 
}

/* About Picture CSS */

#abt {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.aboutSlide {
  background-image: url(../public/assets/images/abt0.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding-bottom: 50%; /* Adjust the padding to maintain aspect ratio */

  /* Media Query for Mobile Phones */
  @media (max-width: 768px) {
    padding-bottom: 100%; /* Increase padding for smaller screens */
  }
}


/* Upcoming Events Slider CSS */
.slider-container {
  position: relative;

    position: relative;
    height: calc(100vh - 250px); /* Assuming the navbar height is 80px */
}

.slider-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.slider-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text-container {
  position: absolute;
  padding: 20px;
  color: #fff;
}

.slider-controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.slider-controls button {
  font-size: 24px;
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
}

.even-slide .text-container {
  left: 20px;
}

.odd-slide .text-container {
  right: 20px;
}
 /* Gallery CSS */

.img-fluid {
  transition: all 0.3s ease; /* Transition */
}
/* Styles for the smaller images */
.bg-cream {
  background-color: #F5FCCD;
}
/* Styles for the smaller images */
.small {
  max-width: 150px; /* Adjust the size according to your needs */
  max-height: 150px; /* Adjust the size according to your needs */
  cursor: pointer;
}

/* Styles for the expanded images */
.expanded {
  max-width: 80%; /* Adjust the size according to your needs */
  max-height: 80%; /* Adjust the size according to your needs */
  cursor: pointer;
}

.image-gallery {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.event-container {
  width: 100%;
  margin-bottom: 20px;
  width: 100%;
}

.img-row {
  display: flex;
  flex-wrap: wrap;
}

.img-container {
  cursor: pointer;
  width: 25%; /* 4 images in a row */
  margin: 5px;
  overflow: hidden;
  transition: flex-basis 0.3s ease-in-out;
}

.img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.small {
  flex-basis: 25%;
}

.expanded {
  flex-basis: 100%;
  cursor: zoom-out;
}

/* Styles for expanded portrait image */
.expanded img.portrait {
  width: 608px;
  height: 1080px;
  object-fit: contain;
}

/* ImageModal.css */

.image-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  /* Add the following properties to prevent background expanding */
  overflow: hidden;
  pointer-events: none;
}

.image-modal {
  position: relative;
  width: 90%;
  max-width: 800px;
  height: 90%;
  max-height: 600px;
  padding: 20px;
  background-color: transparent; /* Set the background color to transparent */
  box-shadow: none; /* Remove box-shadow to eliminate borders */
  /* Add the following transform and pointer-events properties */
  transform: translate3d(0, 0, 0);
  pointer-events: auto;
}

.modal-image {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  z-index: 1;
}

.close-button:hover {
  color: #f44336;
}

.media-row {
  /* ... Existing styles ... */
  display: flex;
  flex-wrap: wrap;
}

.media-container {
  /* Set fixed width for the media containers (images and videos) */
  width: 150px;
  height: 150px;
  margin: 5px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.media-thumbnail {
  /* Set images to cover the container with the same aspect ratio */
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-thumbnail {
  /* Style the play button overlay on top of the video thumbnail */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
}

/* ImageModal.css */

/* Background overlay */
.media-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal container */
.media-modal {
  position: relative; /* Required for positioning the close button */
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
  border: none;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Add overflow: hidden */
  max-width: 100vw;
  max-height: 100vh;
}

/* Portrait images */
.modal-image.portrait {
  max-height: 80vh; /* Maximum visible size for portrait images */
  max-width: 80vw; /* Maximum visible size for portrait images */
}

/* Landscape images */
.modal-image {
  max-height: 90vh; /* Maximum visible size for landscape images */
  max-width: 90vw; /* Maximum visible size for landscape images */
}
/* Video player */
.modal-video {
  max-width: 100%;
  max-height: 100%;
}

/* Close button */
.close-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.5rem;
  padding: 0.5rem; /* Increase padding for larger "X" mark */
  background-color: rgba(255, 255, 255, 0.8); /* Slightly transparent background */
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem; /* Increase font size for larger "X" mark */
  color: black; /* Change color for better visibility */
  transition: background-color 0.3s ease-in-out; /* Add transition effect */
}

.close-button:hover {
  background-color: rgba(255, 255, 255, 1); /* Full opacity on hover for better visibility */
}

.modal-navigation {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 3rem;
  cursor: pointer;
  color: white;
  z-index: 1;
  padding: 1rem;
  user-select: none;
}

.prev-button {
  left: 0;
}

.next-button {
  right: 0;
}


/* Assuming you have a CSS file linked in your HTML */

.thumbnail-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* Zoom in landscape images to match col-md-2 width */
.landscape {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-height: 120px; /* Adjust this value as needed */
}

.col-md-1,
.col-md-10 {
  padding: 0;
}

.col-md-1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.col-md-10 {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* in one shot HistoryRecap.css */

/* HistoryRecap.css */

.glance-div {
  max-width: 400px;
  margin: 0 auto;
}

.event-container {
  display: flex;
  flex-direction: column;
}

.event-item {
  padding: 20px;
  box-sizing: border-box;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
}

.year {
  font-size: 1.5rem;
  margin-bottom: 8px;
}

.description {
  font-size: 1rem;
}

.bg-success {
  background-color: #28a745 !important;
}

.text-white {
  color: white !important;
}


/* sky blue background */
.sky-blue-background {
  background-color: #87CEEB; /* Sky blue color */
}

/* ImgNav.css */

.img-nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.img-item {
  margin: 5px; /* Add margin for spacing between images */
  padding: 5px; /* Add padding from all sides */
  position: relative;
  transition: transform 0.3s;
}
.img-item img {
  max-width: 100%;
  max-height: 100%; /* Maintain the image's aspect ratio within the square container */
  object-fit: cover; /* Crop or stretch the image to cover the entire square */
}

.know-more {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px;
  border-radius: 5px;
  display: none;
}

.blurred:hover {
  filter: blur(4px); /* Adjust the blur intensity as needed */
}

.know-more {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

/* Styling for the "Know More" link */
.know-more a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
}
/* Media query for mobile phones */
@media (max-width: 576px) {
  .container {
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .img-item {
    margin: 5px; /* Reduce margin for spacing between images */
    padding: 5px; /* Reduce padding from all sides */
  }

  .know-more {
    padding: 5px; /* Reduce padding for smaller screens */
  }
}


/* ImgNav.css */

.container {
  padding: 20px;
}

/* Adjust the max-width for the image to make it smaller */
.img-fluid1 {
  max-width: 40%; /* You can adjust this value to make the image smaller */
  height: 40%;
}

/* Media query for phones */
@media (max-width: 576px) {
  .container-ev {
    padding: 10px;
  }

  .p-3 {
    padding: 1rem !important; /* Reduce padding for smaller screens */
  }

  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .col-md-6 {
    width: 100%; /* Full width for text on mobile */
  }

  .img-fluid {
    display: block; /* Remove any margins or padding from the image */
    margin: 0 auto; /* Center the image on mobile */
  }
}



/* POP-Up Poster  CSS*/
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Transparent black background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999; /* Ensure it's above other content */
}

.popup-content {
  background: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 1000; /* Ensure it's above the background */
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

/* KeyFrame - Marquee CSS*/
.scrolling-text {
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide the overflowing text */
  position: relative; /* Required for absolute positioning */

  /* Animation settings */
  animation: scroll-left 20s linear infinite;
}

/* Define the animation */
@keyframes scroll-left {
  0% {
    transform: translateX(100%); /* Start offscreen to the right */
  }
  100% {
    transform: translateX(-100%); /* Scroll to the left */
  }
}